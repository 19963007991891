import React, { useState } from 'react';
import logo from './logo.svg';
import BeautyClinicAnimation from './BeautyClinicAnimations';
import HomeFade1 from './HomeFade1';
import HomeFade2 from './HomeFade2';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { motion } from "framer-motion";


function Home() {
  const [hoveredButton, setHoveredButton] = useState('');

  const handleButtonHover = (buttonText: string) => {
    setHoveredButton(buttonText);
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1000,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className=''>
    <motion.main
    className="main__container"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <div className='absolute text-gray-800'>
            <ul className="circles z-4">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
      <div className='homebg1 w-screen h-[100vh] md:h-fit lg:h-[100vh]  items-center md:grid grid-cols-2 grid-rows-1 splash pb-[25vh] pt-[20vh] md:pt-[30vh] lg:pt-[20vh] text-center z-2'>
        <div>
        <div className='z-2 pointer-events-none drop-shadow-lg'><BeautyClinicAnimation /></div>
        <div className='md:grid grid-cols-1 grid-rows-2 md:gap-20 lg:gap-1 h-[25vh] w-screen md:w-fit z-2 h-fit'>
          <h1 className='drop-shadow-lg text-5xl lg:text-6xl font-semibold text-pink-600 md:pr-10 pb-5 lg:pb-0 mb-0 text-shadow-1'>Upplev</h1>
          <h1 className='drop-shadow-lg text-xl lg:text-3xl md:font-semibold text-pink-500 md:pr-10 pt-0 mt-0'> de senaste anti-aging, estetiska behandlingarna och alternativmedicinska teknikerna i händerna på erfarna och skickliga läkare.</h1>
        </div>
        </div>
      </div>
      <div className='homebg2 flex items-center text-center md:grid grid-rows-1 grid-cols-2 w-screen md:h-[60vw] lg:h-[75vh] pt-20 lg:pr-20 pb-20 md:pb-0 text-gray-800 border-solid border-indigo-900 border-t-4 z-1'>
        <div id="show" className={`hidden md:flex items-center justify-center h-full w-full transition-opacity duration-500 ${hoveredButton ? 'opacity-100' : 'opacity-0'}`}>
          <div id="img" className="shadow-2xl shadow-black rounded-xl h-full w-full flex justify-center items-center ml-10 mr-10 mb-10 text-pink-600" style={{ backgroundImage: hoveredButton === 'Skönhets' ? "url('./botox.jpg')" : "url('./ozonbastu.jpg')", backgroundSize: "cover", backgroundPosition: "center" }}>
            <h1 id="text" style={{lineHeight: "200%"}} className='font-semibold flex text-center backdrop-blur text-shadow-black w-full h-full items-center text-white text-xl lg:text-3xl p-10'>{hoveredButton === 'Skönhets' ? "Våra erbjudanden om skönhetsbehandlingar hjälper dig säkert att uppnå ditt drömutseende i en säker miljö och med erfarna läkare." : "Vårt urval av ozonterapibaserade alternativmedicinska behandlingar är beprövade, testade och används av idrottare och skönhetsikoner runt om i världen."}</h1>
          </div>
        </div>
        <div className='block md:grid md:w-[50%] md:grid-rows-3 md:grid-cols-1 h-full text-center items-center'>
          <HomeFade1 />
          <HomeFade2 />
          <div className='md:flex text-center items-center justify-center md:w-[45vw] pt-20 md:pt-0'>
            <Link to ="/skonhets"><button
              onMouseOver={() => handleButtonHover('Skönhets')}
              onMouseOut={() => handleButtonHover('')}
              className='transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Skönhets
            </button>
            </Link>
            <Link to ="/alternativ"><button
              onMouseOver={() => handleButtonHover('Alternativmedicin')}
              onMouseOut={() => handleButtonHover('')}
              className='transition-all text-xl pr-10 pl-10 bg-blue-800 text-white p-4 rounded-lg border-2 hover:border-blue-800 hover:border-solid hover:border-md hover:bg-white hover:text-blue-800'
            >
              Alternativmedicin
            </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='block md:grid md:grid-rows-1 md:grid-cols-3  h-full w-screen text-center items-center bg-violet-100'> 
      <div id = "kontakt" className='block items-center text-center justify-center p-2 md:p-10 font-serif'>
        <h1 className='text-3xl font-semibold pb-10'>Har du frågor?</h1>
        <p className='text-xl'>Vi är angelägna om att svara på alla frågor som våra kunder har och se till att de hittar den behandling de letar efter.</p>
        <div className='md:text-lg grid grid-rows-4 grid-cols-1 items-center text-center h-full'>
          <br/>
            <div className='pt-2 flex items-center text-center'>
            <p className='font-semibold pr-2'>E-mail:</p><p>contact@essexpress.se</p>
            </div>
            <div className='pt-2 flex items-center text-center'>
            <p className='font-semibold pr-2'>Address:</p><p>Södra Vägen 7</p>
            </div>
            <div className='pt-2 flex items-center text-center'>
            <p className='pr-2'>411 35 Göteborg</p>
            </div>
            <div className="pt-10 flex w-full items-center text-center">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.8626039050964!2d11.972318178013504!3d57.701804073867436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff370729a3973%3A0x67d88b0a08bffa76!2zU8O2ZHJhIFbDpGdlbiA3LCA0MTEgMzUgR8O2dGVib3JnLCBTdmVyaWdl!5e0!3m2!1ssv!2sro!4v1708123593448!5m2!1ssv!2sro" width="600" height="450" className="rounded-xl shadow-lg shadow-black border-0 w-full"></iframe>
            </div>
        </div>
      </div>
      <div className="hidden md:block rounded-xl m-10 h-[75vh] w-[50vw] col-span-2 shadow-lg shadow-black" style={{backgroundImage: "url('./home3.jpg')", backgroundSize: "cover", backgroundPosition:"center"}}></div>
          </div>
    </div>
    </motion.main>
    </div>
  );
}

export default Home;