import React, { useState } from 'react';
import logo from './logo.svg';
import BeautyClinicAnimation from './BeautyClinicAnimations';
import HomeFade1 from './HomeFade1';
import HomeFade2 from './HomeFade2';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Alternativ() {
  const [hoveredButton, setHoveredButton] = useState('');

  const handleButtonHover = (buttonText: string) => {
    setHoveredButton(buttonText);
  };

  return (
    <motion.main
    className="main__container"
    initial={{ opacity: 0}}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <div className='splash text-gray-800 z-[-1]'>
      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-[20vh] md:pt-[30vh] lg:pt-[20vh] text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(197,252,255,1) 20%, rgba(194,255,246,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white'>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Ozonbastu</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='text-center md:text-left p-10 text-lg'>Utforska en unik upplevelse av hälsosam avkoppling med vår exklusiva ozonsaunabehandling på EssExpress. Vår ozonsauna erbjuder en innovativ metod för att främja välbefinnande och hälsa genom att införliva ozon, en kraftfull naturlig gas, i behandlingsprocessen. Ozonsaunabehandlingen på vår klinik är utformad för att främja detox, öka cirkulationen och stärka immunförsvaret. Vår erfarna personal skapar en skräddarsydd och avkopplande upplevelse där du kan njuta av de hälsofrämjande fördelarna med ozon. Vi strävar efter att skapa en trygg och lugn miljö där du kan slappna av och låta ozonsaunabehandlingen optimera din fysiska och mentala hälsa. Kontakta oss idag för att boka din session och upplev de revitaliserande effekterna av ozonsauna hos EssExpress.</p>
            <h1 className='text-2xl font-semibold pt-10'>Vad kan ozonbastu hjälpa till med?</h1>
            <p className='text-center md:text-left p-10 text-lg'>
            Ozonoterapi ökar immuniteten och har en antiviral, antibakteriell och antimycotic effekt. Hos svampar hämmar O3 celltillväxt i vissa stadier. Med virus skadar O3 den virala kapsiden och stör reproduktionscykeln genom att störa virus-till-cell-kontakten med peroxidation. De svaga enzymbeläggningarna på celler som gör dem sårbara för invasion av virus gör dem mottagliga för oxidation och eliminering från kroppen, som sedan ersätter dem med friska celler.
Ozonterapi har använts och studerats omfattande under många decennier totalt. Dess effekter är bevisade, konsekventa och med minimala biverkningar. Medical O3, som används för att desinficera och behandla sjukdomar, har funnits i över 150 år. Används för att behandla infektioner, sår och flera sjukdomar, O3:s effektivitet har varit väldokumenterad. Det har använts för att desinficera dricksvatten före förra sekelskiftet. Ozon var känt för att behandla så många som 114 sjukdomar.
<h1 className='text-2xl font-semibold pt-10 pb-5'>Fördelar med Medical Ozonbastu:</h1>
<ol>
  <li>1.Minskar trötthet, ger ökad energi och förbättrar konditionen.</li>
  <li>2.Lindrar ledsmärtor och reumatism.</li>
  <li>3.Lindrar smärtor orsakade av fibromyalgi och artros.</li>
  <li>4.Förbättrar immunförsvaret.</li>
  <li>5.Behandlar sömnproblem (insomni).</li>
  <li>6.Lindrar hudbesvär som eksem, psoriasis, akne (på kroppen).</li>
  <li>7.Förbättrar blodcirkulation.</li>
  <li>8.Minskar och behandlar celluliter.</li>
  <li>9.Rensar ut kroppen från toxiner.</li>
  <li>10.Förbättrar förbränning av fettdepåer.</li>
  <li>11.Hjälper att tona musklerna.</li>
  <li>12.Antistress.</li>
</ol>
<h1 className='text-2xl font-semibold pt-10 pb-5'>HISTORY OF OZONE THERAPY</h1>
<p className='text-center md:text-left p-10 text-lg'>
Ozone therapy has been utilized and extensively studied for many decades altogether. Its effects are proven, consistent and with minimal side effects. Medical O3, used to disinfect and treat disease, has been around for over 150 years. Used to treat infections, wounds and multiple diseases, O3‘s effectiveness has been well-documented. It has been used to disinfect drinking water before the turn of the last century. Ozone was known to treat as many as 114 diseases. Ozone therapy has been in use since the 1800s and in 1896 the genius Nikola Tesla patented the first O3 generator in the US, later forming the “Tesla Ozone Company.” During the first world war (1914-18) doctors familiar with O3‘s antibacterial properties, and with few other medical resources available to them applied it topically to infected wounds and discovered O3 not only remedied infection, but also had hemodynamic and anti-inflammatory properties.
</p>
            </p>
            <Link to ="/bokning?tjanst=ozonbastu"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'
            >
              Boka nu!
            </button>
            </Link>
          </div>
          <div className='flex justify-center items-center p-5'>
            <img src='./ozonbastu.jpg' className='rounded-lg'/>
          </div>
        </div>
      </div>


      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(255,238,238,1) 20%, rgba(255,210,210,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
        <div className='flex justify-center items-center p-5'>
          <img src='./diabeticfoot.jpg' className='rounded-lg'/>
          </div>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Diabetic foot</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 pb-5 pt-5 text-lg'>De mest spektakulära effekterna av ozonterapi hos diabetiker upplevs hos dem som dyker upp hos läkaren med betydande bensår. För det mesta hamnar de på vår klinik efter att ha provat alla möjliga metoder innan amputation, med kirurger som inte ger dem någon chans att rädda benet. </p>
            <p className='md:text-left p-10 text-lg'> Kontakta oss idag för att boka din konsultation hos EssExpress.</p>
            <Link to ="/bokning?tjanst=diabetic_foot"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'
            >
              Boka nu!
            </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(225,255,202,1) 20%, rgba(210,255,212,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Rektal insuflation</h1>
            <h1 className='text-2xl pt-5'>med ozon</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 text-lg'>Ozon Rektal insufflation ger en avgiftande effekt, hjälper till att regenerera tjocktarmen och har en immunförstärkande effekt. Rektal insufflation, liksom våra andra ozonterapier, är en beprövad metod för att administrera ozon för att förbättra tjocktarmens hälsa.</p>
            <Link to ="/bokning?tjanst=rektal_insuflation"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'
            >
              Boka nu!
            </button>
            </Link>
          </div>
          <div className='flex justify-center items-center p-5'>
          <img src='./rectal.jpg' className='rounded-lg'/>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(0,255,236,1) 20%, rgba(210,255,212,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
        <div className='flex justify-center items-center p-5'>
          <img src='./ozoninjektion.jpg' className='rounded-lg'/>
          </div>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Ozoninjektioner</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 pb-5 pt-5 text-lg'>Nu, när medicinen inte står stilla, ozonterapi började användas mer aktiv. Tack vare användningen av ozon, har sådant terapi en kraftfullt effekt och enligt de flesta läkare, ozonterapi kommer snart att ersätta de flesta läkemedel. </p>
            <p className='md:text-left p-10 text-lg'>Ozon anses vara en kraftfull antiinflamatorisk, antiseptisk, antibakteriell, antiviral, antifungial,förskyndar läkeprocesen, antialergisk, lindrar stress och trötthet, förbättring av sexuella problem för menn.
Då antibiotika inte kan hantera sjukdomen ger behandlingen positiva resultat.  Kontakta oss idag för att boka din konsultation hos EssExpress.</p>
            <Link to ="/bokning?tjanst=Ozoninjektioner"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'
            >
              Boka nu!
            </button>
            </Link>
          </div>
        </div>
      </div>
      
     </div>
     </motion.main>
  );
}

export default Alternativ;