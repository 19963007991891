import React, { useState } from 'react';

interface ScrollableListProps {
  items: string[];
  onItemSelect: (selectedItem: string) => void;
}

const ScrollableList: React.FC<ScrollableListProps> = ({ items, onItemSelect }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
    onItemSelect(items[index]);
  };

  return (
    <div className="overflow-y-auto h-64 border rounded-md h-max w-full">
      <ul className="divide-y divide-gray-200">
        {items.map((item, index) => (
          <li
            key={index}
            onClick={() => handleItemClick(index)}
            className={`p-4 cursor-pointer ${
              selectedIndex === index ? 'bg-blue-200' : 'bg-white'
            }`}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScrollableList;