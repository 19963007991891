import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const BeautyClinicAnimation: React.FC = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);


  return (
    <div className="flex justify-center items-center container">
      <svg
        ref={svgRef}
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 561.08 589.37"
        className="w-[50vw] md:w-[20vw] avatar drop-shadow-2xl"
        fill='#ff99ff'
      >
  <defs>
  </defs>
  <path className="cls-1 radial" d="M325.99,186.09l9.61,9.22-1.93-7.47s11.81,6.75,22.9,7.47c-4.58-3.62-10.6-7.95-10.6-7.95,0,0,16.39,7.95,33.98,6.03-5.78-2.17-12.17-1.45-17.71-9.16,13.01,5.78,35.31,3.86,35.31,3.86,0,0-16.63-2.65-22.41-6.27,15.18,1.45,27.72-2.41,31.33-6.03-9.88,1.93-18.32,.72-24.34,0,13.74,0,24.1-4.1,30.61-11.81-5.3,2.17-14.7,7.95-32.05,5.3-5.3-1.21-6.99-5.3-6.99-5.3,0,0-5.06-6.75-9.88-7.71,4.34,5.06,10.6,10.85,6.51,16.87-3.94,5.79-16.33,12.92-43.39,10.9-1.12-.08-1.74,1.27-.92,2.05Z"/>
  <path className="cls-1" d="M397.78,255.08s5.06,1.93,8.92-1.45,18.32-20.73,18.32-20.73c0,0,8.44-9.88,8.19,2.17s-6.75,12.77-6.75,12.77c0,0,16.15-.72,12.05,12.29-4.1,13.01-20.03,5.78-20.03,5.78,0,0,11.36-1.93,9.91-5.54s-8.92-6.75-15.67-2.89-9.64,2.65-14.94-2.41Z"/>
  <path className="cls-1" d="M314.41,161.04c-.1,.26,.28,.43,.41,.19,2.82-5.35,10.07-15.69,24.4-17.05,19.46-1.85,26.46,.39,28.74,.62,2.7,.27,9.33-3.83,4-7.42s-30.43-2.28-36.09-1.98c-.77,.04-1.5,.31-2.1,.79-3.37,2.67-14.81,12.41-19.37,24.86Z"/>
  <path className="cls-1" d="M424.05,202.06s-10.6,4.58-12.29,8.44,1.69,6.27,3.62,5.06-2.89-4.34,8.68-13.5Z"/>
  <path className="cls-1" d="M303.75,288c-1.04-.39-1.94,.85-1.22,1.69,1.39,1.61,3.1,4.36,5.11,9.01,6.03,13.98,12.05,38.08,4.34,61.94s-15.18,44.35-15.18,44.35l42.66-71.58s7.23-14.46,31.33-20.97c7.71-2.41,54.71-17.35,57.84-23.86-4.56,1.92-91.99,32-121.6,1.55-.86-.89-1.76-1.55-3.27-2.13Z"/>
  <path className="cls-1" d="M294.38,83s-23.86,67.96,22.41,102.67c46.27,34.71-10.12,101.22-52.78,136.65-42.66,35.43-73.03,49.17-76.79,87.97-1.6,16.48,13.16,68.92,55.1,56.63-9.4,7.23-43.74,15.91-69.41-7.95,15.33,19.12,35.43,44.1,76.64,44.1-19.52,6.05-109.18,11.57-107.01-65.8s82.42-109.9,82.42-109.9c0,0-133.04,29.64-103.39,168.46-22.41-9.4-27.45-85.32,2.17-133.76-30.37,14.46-37.6,91.82-37.6,91.82,0,0-42.66-99.78,15.18-150.39,57.84-50.61,130.87-39.28,146.05-108.21-33.02,35.91-82.96,57.22-109.18,67.72-22.96,9.2-64.11,29.68-73.75,91.82-5.06-62.14-1.45-159.07,99.05-183.65-37.6-9.86-92.55,57.88-92.55,57.88,0,0,66.76-146.09,223.41-146.09Z"/>
  <path className="cls-1" d="M257.75,339.91s-24.1,66.04,32.3,94.47c56.4,28.44,19.04,75.19,19.04,75.19,0,0,54.77-41.69,5.06-74.71-35.19-23.38-59.77-40.01-56.4-94.96Z"/>
  <path className="cls-1 radial" d="M258.78,410.3s-12.05,33.02,16.15,47.24c27.1,13.67,10.62,36.22,9.52,37.6,0,0,27.38-20.84,2.53-37.36-17.59-11.69-29.88-20-28.2-47.48Z"/>
</svg>
    </div>
  );
};

export default BeautyClinicAnimation;