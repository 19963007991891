import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';
import { redirect } from "react-router-dom";
import Confirm from './Confirm';
import { useNavigate } from 'react-router-dom';

const Kontakt: React.FC = () => {
  const navigate = useNavigate()
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        number: '',
        message: '',
      });

      const [showChild, setShowChild] = useState(false)
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
          const response = await fetch('https://essexpress.se/contact.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'no-cors',
            //@ts-ignore
            body: new URLSearchParams(new FormData(e.target as HTMLFormElement)),
          });
      
          if (response.ok) {
            window.location.href = "/confirm"
          } else {
            window.location.href = "/confirm"
          }
        } catch (error) {
          window.location.href = "/confirm"
        }
      };

  return (
    <div>
    <motion.main
    className="main__container"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
      {showChild && <Confirm />}
      <div className='md:h-[10vh]'></div>
    <div className='md:grid grid-cols-2 grid-rows-1 splash w-screen h-screen md:h-screen text-black pr-10 pl-10'>
    <div className="container md:mx-auto md:mt-24 flex items-center justify-center w-full h-full">
        <h1 className='text-2xl pb-10 font-semibold text-white'>Skicka ett meddelande till oss så kontaktar vi dig.</h1>
    <div className='flex justify-center items-center w-full'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.8626039050964!2d11.972318178013504!3d57.701804073867436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff370729a3973%3A0x67d88b0a08bffa76!2zU8O2ZHJhIFbDpGdlbiA3LCA0MTEgMzUgR8O2dGVib3JnLCBTdmVyaWdl!5e0!3m2!1ssv!2sro!4v1708123593448!5m2!1ssv!2sro" width="1200" height="450" className="rounded-xl shadow-lg shadow-black border-0 w-[100%]"></iframe>
    </div>
    </div> 
    <div className="container mx-auto mt-24 flex items-center justify-center">
        <form onSubmit={handleSubmit} className='pt-5'>
        <div className='block'>
        <div className="mb-4">
          <label htmlFor="firstname" className="block text-white font-medium">Förnamn</label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            defaultValue={formData.firstname}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastname" className="block text-white font-medium">Efternamn</label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            defaultValue={formData.lastname}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-white font-medium">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            defaultValue={formData.email}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-white font-medium">Meddelande</label>
          <input
            type="text"
            id="message"
            name="message"
            defaultValue={formData.message}
            onChange={handleChange}
            className="w-full h-48 border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        </div>
        <div className='flex w-full'>
        <button
          type="submit"
          className='mb-10 transition-all text-xl mt-10 pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'>
          Skicka
        </button>
        </div>
      </form>
    </div> 
    </div>
    </motion.main>
    </div>
  );
};

export default Kontakt;