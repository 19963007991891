import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  useLocation
} from "react-router-dom";
import './index.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './Home';
import Skonhets from './Skonhets';
import Alternativ from './Alternativ';
import AppointmentForm from './AppointmentForm';
import Tjanster from './Tjanster';
import { AnimatePresence } from 'framer-motion'
import Kontakt from './Kontakt';
import Confirm from './Confirm';
import UniversalForm from './UniversalForm';


export default function App(){
    const location = useLocation();
return(
    <div className='absolute w-screen'>
            <Navbar/>
              <div  style={{ zIndex: -100000 }} className='pointer-events-none splash2 fixed w-full h-full flex items-center justify-center'>
      <img src="/logo.svg" className="drop-shadow-lg drop-shadow mb-5 inline-flex w-48 md:w-[18%] mr-2 md:mr-8" alt="logo" />
      </div>
    <AnimatePresence mode='wait'
      onExitComplete={() => {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: 0 })
        }
      }}>
        <Routes location={location} key={location.pathname}>
                <Route index element={<Home />} />
                <Route path="/skonhets" element={<Skonhets />}/>
                <Route path="/alternativ" element={<Alternativ />}/>
                <Route path="/bokning" element={<AppointmentForm />}/>
                <Route path="/tjanster" element={<Tjanster />}/>
                <Route path="/kontakt" element={<Kontakt />}/>
                <Route path="/confirm" element={<Confirm />}/>
                <Route path="/boka" element={<UniversalForm />}/>
        </Routes>
        </AnimatePresence>
    </div>
);
    }