import React, { useState } from 'react';
import logo from './logo.svg';
import BeautyClinicAnimation from './BeautyClinicAnimations';
import HomeFade1 from './HomeFade1';
import HomeFade2 from './HomeFade2';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Confirm() {
  const [hoveredButton, setHoveredButton] = useState('');

  const handleButtonHover = (buttonText: string) => {
    setHoveredButton(buttonText);
  };

  return (
    <div className='w-screen h-screen absolute flex items-center justify-center text-center'>
    <div className='w-full h-full bg-[#00000090] absolute flex items-center justify-center text-center z-10'>
        <div className='bg-white w-fit h-fit text-center justify-center flex splash rounded-2xl'>
            <div className='text-center'>
            <h1 className='font-semibold text-3xl p-10 text-white'>Tack för att du kontaktar oss!</h1>
            <h1 className=' text-xl p-10 text-white'>Vi kommer att kontakta dig inom kort via de kontaktuppgifter du angett.</h1>
            <Link to="/"><p className='text-white text-xl font-bold underline hover:text-yellow-600'>Okej</p></Link>
            </div>
        </div>
    </div>
    </div>
  );
}

export default Confirm;