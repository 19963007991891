import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

function Navbar() {

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [scrolled, setScrolled] = useState(false);
  
    const toggleMobileMenu = () => {
      setMobileMenuVisible(!mobileMenuVisible);
    };
  
    const closeMobileMenu = () => {
      setMobileMenuVisible(false);
    };
  

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1000,
      smooth: 'easeInOutQuart',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100; // Adjust the scroll amount as needed
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`duration-700 bg-indigo-800 bg-opacity-0 transition-all fixed w-screen ${scrolled ? 'bg-opacity-70' : ''}`}  style={{ zIndex: 1 }}>
      <nav className="flex items-center justify-between">
        <Link to="/" className="cursor-pointer flex items-center w-[33vw]">
          <img src="/logo.svg" className="mb-5 inline-flex w-full md:w-[18%] mr-2 md:mr-8" style={{filter: "drop-shadow( 0px 0px 5px rgba(255, 255, 255, .7))", WebkitFilter: "drop-shadow( 0px 0px 5px rgba(255, 255, 255, .7))"}} alt="logo" />
          <h1 className="text-2xl md:text-4xl lg:text-6xl spicy text-white">essexpress</h1>
        </Link>
            <div className="hidden md:inline-flex w-[33vw] items-center justify-between text-white ml-5 ">
                <Link to="/tjanster" className="text-xl lg:text-2xl transition-all hover:text-cyan-200 font-semibold hover:text-3xl">
                    <p className=''>
                        Tjänster
                    </p>
                </Link>
                <Link to="/boka" className="text-xl lg:text-2xl transition-all hover:text-cyan-200 font-semibold hover:text-3xl">
                    <p className=''>
                        Boka nu
                    </p>
                </Link>
                <Link to="/kontakt" className="text-xl lg:text-2xl transition-all font-semibold hover:text-cyan-200 hover:text-3xl">
                    <p>
                        Kontakt
                    </p>
                </Link>
            </div>
            <div className='flex justify-end'>
            <div className="w-[33vw] justify-between items-center flex pr-10">
                <div className="w-full flex justify-end h-[10vh]">
            <Link to="https://www.facebook.com/profile.php?id=100064054091202" className='hidden transition-all cursor-pointer md:flex items-center w-20 hover:w-24'>
            <img src='./facebook.svg' className='inline-flex' alt='logo'/>
            </Link>
            <Link to="https://www.instagram.com/essexpressmedical/" className='hidden transition-all cursor-pointer md:flex items-center w-20 hover:w-24'>
            <img src='./instagram.svg' className='inline-flex' alt='logo'/>
            </Link>
                <button
                    className="text-[#694a60] hover:text-gray-200 focus:outline-none focus:text-gray-200 rounded-full ml-2"
                    onClick={toggleMobileMenu}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke="#000000"
                        className="w-8 md:w-14"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                            stroke='white'
                            d="M4 6h16M4 12h16M4 18h16"
                            className='transition-all hover:stroke-2'
                        />
                    </svg>
                </button>
                </div>
            </div>

            <div className={`fixed inset-0 bg-black opacity-20 z-10 transition-opacity ${mobileMenuVisible ? 'visible' : 'hidden'}`} style={{ opacity: mobileMenuVisible ? '0.5' : '0' }}></div>
        <div className={`fixed bg-white rounded right-0 w-screen md:w-[30vw] md:mr-10 transform transition-transform translate-x-0 ${mobileMenuVisible ? '' : 'hidden md:hidden'}`} id="mobile-menu" style={{ zIndex: "20" }}>
          <button
            className="text-[#694a60] hover:text-gray-200 focus:outline-none focus:text-gray-200 rounded-full ml-2"
            onClick={closeMobileMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke="#000000"
              className="w-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                stroke='black'
                d="M6 18L18 6M6 6l12 12"
                className='transition-all hover:stroke-2'
              />
            </svg>
          </button>
          <Link to="/" onClick={() => {  closeMobileMenu(); }} className="block text-3xl text-[#694a60] hover:text-gray-200 py-2">
            <p>
              Hem
            </p>
          </Link>
          <Link to="/tjanster" onClick={() => {  closeMobileMenu(); }} className="block text-3xl text-[#694a60] hover:text-gray-200 py-2">
            <p>
              Tjänster
            </p>
          </Link>
          <Link to="/boka" onClick={() => {  closeMobileMenu(); }} className="block text-3xl text-[#694a60] hover:text-gray-200 py-2">
            <p>
              Boka nu
            </p>
          </Link>
          <Link to="/kontakt" onClick={() => {  closeMobileMenu(); }} className="block text-3xl text-[#694a60] hover:text-gray-200 py-2">
            <p>
              Kontakta oss
            </p>
          </Link>
        </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;