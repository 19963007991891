import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';
import { redirect } from "react-router-dom";
import Confirm from './Confirm';
import ReactGA from "react-ga4";
import ScrollableList from './ScrollableList';

ReactGA.initialize("G-2B2V72TKS7");

let dateformatInst = 'unset'
const UniversalForm: React.FC = () => {


  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleItemSelect = (item: string) => {
    setSelectedItem(item);
  };

  const items = [
    'PRP',
    'Botox',
    'Filler',
    'Dubbelhaka',
    'PRP-injektion',
    'PDO Trådlyft',
    'Ozonbastu',
    'Diabetic foot',
    'Rektal insuflation',
    'Ozoninjektioner',
  ];


  const location = useLocation();
  const navigate = useNavigate()
  const service = new URLSearchParams(location.search).get('tjanst') || '';
    const [formData, setFormData] = useState({
      firstname: '',
      lastname: '',
      dateformat: 'eeeeeee',
      preferredDate: new Date(),
      number: '',
      service: selectedItem == null ? "" : selectedItem.toString(),
    });

    const [showChild, setShowChild] = useState(false)
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleDateChange = (date: Date) => {
      const formattedDate = date.toDateString()
      console.log(formattedDate)
      setFormData({
        ...formData,
        preferredDate: date,
        dateformat: "e"
      });
      dateformatInst = formattedDate
    };
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
    
      try {
        const formData = new FormData(e.target as HTMLFormElement);
        formData.set('service', selectedItem == null ? "" : selectedItem.toString()); // Add the service to the form data
        formData.set('dateformat', dateformatInst)
        const response = await fetch('https://essexpress.se/appointment.php', {
          method: 'POST',
          body: formData, // Use FormData directly
          mode: 'no-cors',
        });
        if (response.ok) {
          window.location.href = "/confirm"
          ReactGA.event({
            category: "conversion",
            action: "send_to",
            label: "AW-16480687015/j3PdCMeqm5gZEKenzbI9", // optional
          });
        } else {
          window.location.href = "/confirm"
        }
      } catch (error) {
        window.location.href = "/confirm"
      }
    };

  return (
    <div>
    <motion.main
    className="main__container"
    initial={{ opacity: 0 }}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
  {showChild && <Confirm />}
    <div className='md:grid grid-cols-2 grid-rows-1 p-10 splash w-screen h-fit md:h-screen text-black'>
    <div className="container mx-auto mt-24 flex items-center justify-center">
        <h1 className='text-4xl pb-10 font-semibold text-white'>{selectedItem == null ? "" : selectedItem.toString().replaceAll("_", " ") + " Möte förfrågan"}</h1>
        <form onSubmit={handleSubmit} className='pt-5'>
        <div className='grid grid-cols-1 grid-rows-6 md:grid-cols-2 md:grid-rows-2 gap-10 md:gap-20'>
        <div className="mb-4">
          <label htmlFor="firstname" className="block text-white font-medium">Förnamn</label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            defaultValue={formData.firstname}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastname" className="block text-white font-medium">Efternamn</label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            defaultValue={formData.lastname}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="number" className="block text-white font-medium">Telefonnummer</label>
          <input
            type="number"
            id="number"
            name="number"
            defaultValue={formData.number}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-white font-medium">Önskat datum</label>
          <DatePicker
            selected={formData.preferredDate}
            onChange={handleDateChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#2600f6]"
            required
          />
        </div>
        </div>
        <div className='flex w-full'>
        <button
          type="submit"
          className='mb-10 transition-all text-xl mt-10 pr-10 pl-10 bg-blue-500 text-white p-4 rounded-lg border-2 hover:border-blue-500 hover:border-solid hover:border-md hover:bg-white hover:text-blue-500'>
          Skicka
        </button>
        </div>
      </form>
    </div>
    <div className="container md:mx-auto md:mt-24 flex items-center justify-center w-full">
      <h1 className='text-white text-3xl pb-5'>Önskad service</h1>
        <ScrollableList items={items} onItemSelect={handleItemSelect} />
        </div> 
    </div>
    <div className="container md:mx-auto md:mt-24 flex items-center justify-center w-full">
        <h1 className='text-4xl pb-10 font-semibold text-white'>Har du fragor? <Link to = "/kontakt" className='underline hover:text-blue-200'>Kontakta oss!</Link></h1>
    <div className='flex justify-center items-center w-full'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.8626039050964!2d11.972318178013504!3d57.701804073867436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff370729a3973%3A0x67d88b0a08bffa76!2zU8O2ZHJhIFbDpGdlbiA3LCA0MTEgMzUgR8O2dGVib3JnLCBTdmVyaWdl!5e0!3m2!1ssv!2sro!4v1708123593448!5m2!1ssv!2sro" width="600" height="450" className="rounded-xl shadow-lg shadow-black border-0 w-full"></iframe>
    </div>
    </div> 
    </motion.main>
    </div>
  );
};

export default UniversalForm;