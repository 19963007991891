import React, { useRef, useEffect, useState } from 'react';

const HomeFade1: React.FC = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 } // Adjust the threshold as needed
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={textRef} className={`opacity-0 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : ''}`}>
        <div className='md:w-[50vw] text-center md:pr-20'>
      <h1 className='text-pink-600 font-bold text-2xl shadow-white drop-shadow-lg md:text-3xl lg:text-4xl font-serif pb-10 md:pb-0'>Skönhets- och alternativmedicinklinik</h1>
      </div>
    </div>
  );
};

export default HomeFade1;