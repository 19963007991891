import React, { useState } from 'react';
import logo from './logo.svg';
import BeautyClinicAnimation from './BeautyClinicAnimations';
import HomeFade1 from './HomeFade1';
import HomeFade2 from './HomeFade2';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Skonhets() {
  const [hoveredButton, setHoveredButton] = useState('');

  const handleButtonHover = (buttonText: string) => {
    setHoveredButton(buttonText);
  };

  return (
    <motion.main
    className="main__container"
    initial={{ opacity: 0}}
     animate={{ opacity: "100%" }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.75 }}
 >
    <div className='splash text-gray-800'>
      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-[20vh] md:pt-[30vh] lg:pt-[20vh] text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(0,223,255,1) 19%, rgba(0,51,255,1) 78%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white'>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>PRP</h1>
            <h1 className='text-2xl pt-5'>Vampyrbehandlingen</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='text-center md:text-left p-10 text-lg'>Den senaste Anti-aging behandlingen där man med hjälp av ditt egna blod injicerar det i området som ska behandlas. Behandlingen har visat goda resultat på djupa rynkor, mörka ringar runt ögonen, händer, dekolletage och att förbättra hudens kvalitet. PRP-behandling (Vampyrbehandling) är en naturlig och säkert behandlingsmetod som effektivt reducerar ålderstecken som rynkor, slapp hud och ojämnheter.</p>
            <h1 className='text-2xl font-semibold pt-10'>Hur går PRP-behandlingen till?</h1>
            <p className='text-center md:text-left p-10 text-lg'>
            Vid en PRP behandling centrifugeras en liten dos av patientens eget blod så att de små blodplättarna separeras från resten av blodet. Därefter injiceras den koncentrerade substansen under kontrollerade former där huden behöver nytt liv. På Complete Skin genomför vi vanligen PRP-behandlingar på ansikte, dekolletage, hals och händer.
            </p>
            <Link to ="/bokning?tjanst=prp"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
          <div className='flex justify-center items-center p-5'>
            <img src='./prp2.jpg' className='rounded-lg'/>
          </div>
        </div>
      </div>


      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(4,215,255,1) 20%, rgba(0,255,227,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
        <div className='flex justify-center items-center p-5'>
          <img src='./botox.jpg' className='rounded-lg'/>
          </div>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Botox</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 pb-5 pt-5 text-lg'>Utforska möjligheterna till ungdomlig förnyelse med vår exklusiva Botox-behandling. På EssExpress är vi dedikerade till att erbjuda den senaste inom estetisk medicin, och vår Botox-service är inget undantag. Botox är en beprövad och effektiv metod för att reducera synliga rynkor och fina linjer. </p>
            <p className='md:text-left p-10 pb-5 pt-5 text-lg'>Våra skickliga och certifierade läkare skräddarsyr varje behandling för att passa dina unika behov och önskemål. Vi strävar efter att skapa ett naturligt och ungdomligt resultat, samtidigt som vi ger dig den trygghet och professionalitet som du förtjänar. Besök vår klinik för en personlig konsultation där vi går igenom dina mål och skräddarsyr en Botox-behandling som passar just dig. Återfå en slätare och mer ungdomlig hud med hjälp av våra skräddarsydda Botox-injektioner.</p>
            <p className='md:text-left p-10 text-lg'> Kontakta oss idag för att boka din konsultation och upplev fördelarna med Botox hos EssExpress.</p>
            <Link to ="/bokning?tjanst=botox"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(21,151,255,1) 20%, rgba(21,131,255,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Filler</h1>
            <h1 className='text-2xl pt-5'>Hyaluronsyra</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 text-lg'>Hyaluronsyra är en naturlig substans som finns i kroppen och spelar en nyckelroll i att bevara hudens elasticitet och fuktbalans. Genom noggrant utvalda hyaluronsyrafyllmedel skapar våra erfarna och certifierade läkare en skräddarsydd behandlingsplan för att adressera dina individuella behov och önskemål. Vår klinik strävar efter att erbjuda en trygg och professionell miljö där du kan känna dig säker under hela behandlingsprocessen.</p>
            <p className='md:text-left p-10 text-lg'>
            Med våra hyaluronsyrafyllmedel kan vi skulptera och forma ansiktet för att ge dig det naturliga och ungdomliga utseende du strävar efter.
            </p>
            <Link to ="/bokning?tjanst=hyaluron"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
          <div className='flex justify-center items-center p-5'>
          <img src='./filler.jpg' className='rounded-lg'/>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(252,255,127,1) 20%, rgba(255,217,127,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
        <div className='flex justify-center items-center p-5'>
          <img src='./dubbelhaka.jpg' className='rounded-lg'/>
          </div>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>Dubbelhaka</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 text-lg'>För många är dubbelhakan  svår att bli av med även vid viktnedgång. Man kan idag använda sig av olika metoder för att bli av med sin dubbelhaka. På TBK kan vi erbjuda fettsugning eller icke-kirurgisk behandling med Belkyra vid mindre dubbelhakor.</p>
            <p className='md:text-left p-10 text-lg'>
            Fettcellerna  elimineras  gradvis. Till skillnad från andra injektionsbehandlingar är effekten bestående. Din behandlare injicerar små mängder av läkemedlet på flertalet injektionspunkter i det område som ska behandlas.  Efter behandlingen kan du uppleva mindre smärta, knölighet, svullnad/vätskeansamling, blåmärken eller minskad känslighet under hakan. De här effekterna är i allmänhet tillfälliga och försvinner vanligtvis av sig själva. Biverkningarnas förekomst, svårighetsgrad och varaktighet är i allmänhet störst efter det första behandlingstillfället och minskar i takt med att behandlingarna fortsätter.
            </p>
            <Link to ="/bokning?tjanst=dubbelhaka"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(127,255,183,1) 20%, rgba(127,255,204,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>PRP-injektion</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 text-lg'>PRP är en icke-kirurgisk metod som hjälper och accelererar den naturliga läkningen av leder, muskler och senor. Man använder kroppens eget material och för in det i den skadade kroppsdelen.</p>
            <p className='md:text-left p-10 text-lg'>
            Det egna materialet som används är trombocyterna (blodplättarna, platelet på engelska). Trombocyterna centrifugers ut från patientens eget blod, och sprutas sedan tillbaka in i den onda leden.
            </p>
            <Link to ="/bokning?tjanst=PRP-injektion"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
          <div className='flex justify-center items-center p-5'>
          <img src='./prpinjektion.jpg' className='rounded-lg'/>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-screen h-fit items-center pb-[10vh] pt-10 text-center'
      style={{background: "linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(197,220,255,1) 20%, rgba(166,201,255,1) 80%, rgba(0,212,255,0) 100%)"}}>
        <div className='rounded-xl md:grid grid-cols-2 grid-rows-1 w-[90vw] h-fit bg-white '>
        <div className='flex justify-center items-center p-5'>
          <img src='./pdo.jpg' className='rounded-lg'/>
          </div>
          <div>
            <h1 className='text-3xl font-semibold pt-10'>PDO Trådlyft</h1>
            <hr className='leading-10 border-2 m-10'/>
            <p className='md:text-left p-10 text-lg'>Ett trådlyft kan vara den perfekta behandlingen för dig som känner att du önskar uppstramning och ett lättare lyft men inte är riktigt redo för ett kirurgiskt ingrepp.
Behandlingen ger ett lätt, mjukt och naturligt lyft. Det är dessutom ett minimalt invasivt ingrepp som lämnar dig utan ärr eller långa återhämtningstider.</p>
          
            <Link to ="/bokning?tjanst=pdo_tradlyft"><button
              className='mb-10 transition-all text-xl pr-10 pl-10 bg-pink-800 text-white p-4 rounded-lg border-2 hover:border-pink-800 hover:border-solid hover:border-md hover:bg-white hover:text-pink-800'
            >
              Boka nu!
            </button>
            </Link>
          </div>
        </div>
      </div>

     </div>
     </motion.main>
  );
}

export default Skonhets;