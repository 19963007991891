import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Tjanster() {

    return (
        <div>
        <motion.main
        className="main__container"
        initial={{ opacity: 0 }}
         animate={{ opacity: "100%" }}
         exit={{ opacity: 0 }}
         transition={{ duration: 0.75 }}
     >
        <div className='w-screen h-screen items-center splash pt-[20vh] md:pt-[30vh] lg:pt-[20vh] text-center'>
        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 h-[75vh] w-screen gap-1 p-1'>
            <div className='group'>
            <Link to="/skonhets" className='w-full h-full'>
            <div className='group-hover:backdrop-filter-none text-indigo-800 text-3xl md:text-6xl flex items-center hover:text-7xl hover:text-white justify-center bg-black w-full h-full' style={{ backgroundImage: "url('./filler.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                <h1 className='flex items-center text-shadow-indigo justify-center group-hover:backdrop-filter-none group-hover:text-shadow backdrop-grayscale font-semibold stroke-white transition-all backdrop-inherit w-full h-full'>Skönhets</h1>
            </div>
            </Link>
            </div>
            <div className='group'>
            <Link to="/alternativ" className='w-full h-full'>
            <div className='group-hover:backdrop-filter-none text-green-800 text-3xl md:text-6xl flex items-center hover:text-7xl hover:text-white justify-center bg-black w-full h-full' style={{ backgroundImage: "url('./ozonbastu.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
            <h1 className='flex items-center justify-center text-shadow-green group-hover:backdrop-filter-none group-hover:text-shadow backdrop-grayscale font-semibold stroke-white transition-all backdrop-inherit w-full h-full'>Alternativmedicin</h1>
            </div>
            </Link>
            </div>
        </div>
        </div>
        </motion.main>
        </div>
    )
    };
    

export default Tjanster;